import React, { Component, Fragment } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";


import UploadService from "../services/upload-files.service";
import UserService from "../services/user.service";

import { Multiselect } from 'multiselect-react-dropdown';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';

//import DatePicker from "react-datepicker";
import { DatePicker } from '@y0c/react-datepicker';
import { connect } from 'react-redux';
import { format } from "date-fns";
import { useTable } from 'react-table'

import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios'
import Select from 'react-select'
import { Button } from 'bootstrap';
import ReactDatatable from '@ashvin27/react-datatable';
import Modal from 'react-modal';
//import Table from 'rc-table';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
//import SelectSearch, { fuzzySearch } from '../src'; 

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const subtitle = "";
const Listedeger = [];

export default class UploadImages extends Component {
    constructor(props) {
        super(props);
        this.selectFile = this.selectFile.bind(this);
        this.upload = this.upload.bind(this);
        this._onChange = this._onChange.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.listeguncelle = this.listeguncelle.bind(this);
        this.listesil = this.listesil.bind(this);
        this.tumlisteyisil = this.tumlisteyisil.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.bastarhandleChange = this.bastarhandleChange.bind(this);
        this.bittarhandleChange = this.bittarhandleChange.bind(this);
        this.ListpathhandleChange = this.ListpathhandleChange.bind(this);
        this.ListIDhandleChange = this.ListIDhandleChange.bind(this);
        this.ListenamehandleChange = this.ListenamehandleChange.bind(this);
        this.AktifListehandleChange = this.AktifListehandleChange.bind(this);

        this.columns = [
            {
                key: "id",
                text: "id",
                // className: "address",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <form>
                            <label>
                                <input readOnly={true} type="text" value={record.id} name="id" />
                            </label>

                        </form>
                    );
                }
            },
            {
                // key: "ListID",
                text: "ListeID",
                // className: "address",
                align: "left",
                sortable: true,
                cell: (record, index) => {
                    return (
                        <form>
                            <label>
                                <textarea type="text"
                                    name="ListeID"
                                    onChange={this.ListIDhandleChange} >

                                    {record.ListID}
                                </textarea>
                            </label>

                        </form>
                    );
                }
            },

            {
                key: "Listename",
                text: "List Adı",
                className: "name",
                align: "left",
                sortable: true,
                cell: (record, index) => {
                    return (
                        <form>
                            <label>
                                <textarea type="text"
                                    name="ListeID"
                                    onChange={this.ListenamehandleChange} >
                                    {record.Listename}
                                </textarea>
                            </label>

                        </form>
                    );
                }
            },
            {
                key: "bastar",
                text: "Başlangıç Tarihi",
                className: "name",
                align: "left",
                sortable: true,
                cell: (record, index) => {
                    return (
                        <form>
                            <textarea type="text"
                                name="bastar"
                                onChange={this.bastarhandleChange} >
                                {record.bastar}
                            </textarea>
                            {/*  <DatePicker
                                onChange={this.onChange} />

                             <DatePicker 
                            value={this.state.startDate}
                            onChange={this.bastarhandleChange} /> */}

                        </form>
                    );
                }
            },
            {
                key: "bittar",
                text: "Bitiş Tarihi",
                className: "name",
                align: "left",
                sortable: true,
                cell: (record, index) => {
                    return (
                        <form>
                            <textarea type="text"
                                name="bittar"
                                onChange={this.bittarhandleChange} >

                                {record.bittar}
                            </textarea>
                        </form>
                    );
                }
            },
            {
                key: "AktifListe",
                text: "Aktif / Pasif Listeler",
                className: "AktifListe",
                align: "left",
                sortable: true,
                cell: (record, index) => {
                    return (
                        <form>
                            <label>
                                <input readOnly={true} type="text"
                                    onChange={this.AktifListehandleChange}
                                    value={record.AktifListe} name="id" />
                            </label>
                        </form>
                    );
                }
            },
            {
                key: "Listpath",
                text: "Liste içeriği",
                className: "name",
                align: "left",
                sortable: true,
                cell: (record, index) => {


                    return (
                        // this.setState({ Listedeger: record }),
                        <form>
                            <label>
                                <textarea type="text"
                                    //hidden="hidden"
                                    value={record.Listpath.toString().split(",")}

                                    name="Listpath"
                                    onChange={(evt) => this.setState({ record: evt.target.value })
                                        //,console.log(record)
                                    }
                                />
                            </label>


                            {
                                     record.Listpath.toString().split(",").map((records) => (

                                        <Fragment>

                                            <p />
                                            <img src={"https://mymedyal.madilink.net/files/" + records} height="150px" />
                                            <p />
                                            <button
                                                className="btn btn-danger btn-lg"
                                                value={records}
                                                onClick={
                                                    () =>
                                                        //console.log(JSON.stringify({record})),
                                                    // console.log(record.Listpath.toString().split(","))
                                                    // ,
                                                    this.DeleteFiles(records)
                                                }
                                                style={{ marginRight: '5px' }}>
                                                <i className="fa fa-edit"></i>
                                                Resmi Sil
                                            </button>

                                            <p />
                                        </Fragment>
                                    ))
 
                            }




                        </form>
                    );
                }
            },
            {
                key: "İşlemler",
                text: "İşlemler",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-success btn-lg"
                                onClick={() => this.AktifListeAktif(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit"></i>
                                Liste Aktif
                            </button>
                            <p />
                            <button
                                className="btn btn-danger btn-lg"
                                onClick={() => this.AktifListePasif(record)}
                                style={{ marginRight: '5px' }}>
                                Liste Pasif
                            </button> <p />
                            <button
                                className="btn btn-danger btn-lg"
                                onClick={() => this.listesil(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit"></i>
                                Liste Kaldır
                            </button> <p />


                            <button
                                className="btn btn-warning btn-lg"
                                onClick={() => this.listeguncelle(record)}

                                type="submit"
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit"></i>
                                Liste Düzenle
                            </button> <p />

                        </Fragment>
                    );
                }
            }


        ];
        this.config = {
            //page_size: 10,
            length_menu: [20, 20, 20, 20, 20, 20, 20, 20],
            button: {
                excel: false,
                print: false,
                extra: false,
            },
            show_filter: false,
            show_pagination: false,
            sort: false,
            show_length_menu: false,
            show_info: false,
            language: {
                loading_text: "Sakin bebebeğim veriler Yükleneyo... :) "
            }
        }
        this.state = {
            base: "https://mymedyal.madilink.net",
            currentFile: undefined,
            previewImage: undefined,
            progress: 0,
            message: "",
            listInfos: [],
            selectedOption: [],
            modalIsOpen: null,
            imageInfos: [],
            id: null,
            bastar: "",
            bittar: "",
            Listpath: "",
            ListID: "",
            Listename: "",
            AktifListe: "",
            value: [],
            label: [],
            error: null,
            isLoaded: false,
            loading: true,
            records: this.listInfos,
            row_clicks: [],
            sweetArray2: [],
            sweetArray3: [],

        };
    }
    bastarhandleChange(event) {
        this.setState({ bastar: event.target.value });
        ////console.log(this.state.bastar);
    }
    bittarhandleChange(event) {
        this.setState({ bittar: event.target.value });
        //console.log(this.state.bittar);
    }
    ListpathhandleChange(event) {

        this.setState({ Listpath: event });
        ////console.log(this.state.ListID);
    };
    ListIDhandleChange(event) {
        this.setState({ ListID: event.target.value });
        ////console.log(this.state.ListID);
    }
    ListenamehandleChange(event) {
        this.setState({ Listename: event.target.value });
        ////console.log(this.state.Listename);
    }
    AktifListehandleChange(event) {
        this.setState({ AktifListe: event.target.value });
        ////console.log(this.state.AktifListe);
    }
    handleOpenModal() {
        this.setState({ showModal: true });
    }
    handleCloseModal() {
        this.setState({ showModal: false });
    }
    load() {
        setTimeout(() => {
            this.setState({
                loading: false,
                records: this.state.listInfos
            })
        }, 3000);

        UploadService.getFiles().then((response) => {
            this.setState({
                imageInfos: response.data,
                //  svalue: response.data.name,
            });

        });

        UploadService.getList().then((response) => {
            this.setState({
                listInfos: response.data,
            });

            const sweetArray = this.state.listInfos.map(image => image.Listpath).toString().split('https://mymedyal.madilink.net/Airjin/tumlistegetir').filter(e => e)
            const sweetArray3 = sweetArray.toString().split(',').filter(e => e)
            //  ////console.log(sweetArray3)
            this.setState({ sweetArray3 })
            // const sweetArray3 = sweetArray.map(image => image.replace(".jpg,", ".jpg"));

            // const entries = Object.values(sweetArray);
            // this.setState({ entries })
            ////console.log(entries)
            ////console.log(sweetArray3)

        });
        this.renderData();
    }
    componentDidMount() {

        this.load()


    }
    async renderData() {
        const API = await axios.get(this.state.base + '/files')
        const serverResponse = API.data



        const dropDownValue = serverResponse.map((response) => ({

            value: this.state.base + "/files/" + response.value,
            label: response.value,
        })

        )



        this.setState(
            {
                dropDownOpt: dropDownValue
            }
        )


    }
    _onChange(event) {
        this.setState({
            Selected_filename: event,
            Selected_originalname: event,

        }

        )



    }
    selectFile(event) {
        this.setState({
            currentFile: event.target.files[0],
            previewImage: URL.createObjectURL(event.target.files[0]),
            progress: 0,
            message: ""
        });
    }
    upload() {
        this.setState({
            progress: 0,
        });

        UploadService.upload(this.state.currentFile, (event) => {
            this.setState({
                progress: Math.round((100 * event.loaded) / event.total),
            });
        })
            .then((response) => {
                this.setState({
                    message: response.data.message,
                });
                return UploadService.getFiles();
            })
            .then((files) => {
                this.setState({
                    imageInfos: files.data,
                });
            })
            .catch((err) => {
                this.setState({
                    progress: 0,
                    message: "Could not upload the image!",
                    currentFile: undefined,
                });
            });
    }
    tumlisteyisil() {
        UserService.hepsinisil()
            .then(response => {
                ////console.log(response.data);

            })
            .catch(e => {
                ////console.log(e);
            });
        this.load()
    }
    listeguncelle = (record) => {
        const id = record.id;

        const Listpath = this.state.Listpath;
        const Listpath2 = record.Listpath;

        const bastar = this.state.bastar;
        const bastar2 = record.bastar;

        const bittar = this.state.bittar;
        const bittar2 = record.finishDate;


        const AktifListe = this.state.AktifListe;
        const AktifListe2 = record.AktifListe;

        const ListID = this.state.ListID;
        const ListID2 = record.ListID;

        const Listename = this.state.Listename;
        const Listename2 = record.Listename;

        if (ListID == "") {
            this.setState({ ListID: ListID2 })
            //console.log("ayni.ListID ")
            //console.log(ListID2)

        }
        else {

            this.setState({ ListID })
            //console.log(" degismis.ListID ")
            //console.log(ListID)

        }


        if (Listename == "") {

            this.setState({ Listename: Listename2 })
            //console.log("aynı.Listename ")

            //console.log(Listename)
        }
        else {
            this.setState({ Listename })
            //console.log("farklı.Listename ")

            //console.log(Listename)
        }



        if (bastar == "") {

            this.setState({ bastar: record.bastar })
            //console.log(bastar)
        }
        else {
            this.setState({ bastar })
            //console.log("bastar")
            //console.log(bastar)

        }




        if (bittar == "") {

            this.setState({ bittar: record.bittar })
            //console.log(bittar)
        }
        else {
            this.setState({ bittar })
            //console.log("bittar")
            //console.log(bittar)

        }


        if (AktifListe == "") {

            this.setState({ AktifListe: AktifListe2 })
            //console.log(AktifListe)
        }
        else {
            this.setState({ AktifListe })
            //console.log(AktifListe)
        }
        if (Listpath == "") {

            this.setState({ Listpath: Listpath2 })
            //console.log(Listpath)
        }
        else {
            this.setState({ Listpath })
            //console.log(Listpath)
        }

        const data = {
            bastar: bastar,
            bittar: this.state.bittar,
            Listpath: this.state.Listpath,
            ListID: this.state.ListID,
            Listename: this.state.Listename,
            AktifListe: this.state.AktifListe
        };
        console.log(data)





        UserService.listeguncelle(id, data)

            .then(() => {

                this.setState({
                    message: "The List was Update successfully!"
                });


            })
            .catch(e => {
                ////console.log(e);
            });


        this.load()

    }
    Dizindensil(value) {

        UploadService.DeleteFiles(value).
            then(response => {
                this.setState({
                    message: "The image was updated delete!"
                });

            })
            .catch(e => {
                ////console.log(e);
            })
        this.sayfayiyenile();

    }
    DeleteFiles = (records, record, index) => {

        const value = records
        //console.log(value)

        const value2 = record
        console.log(value2)


        /* this.state.Listpath.map(test=>{
            console.log(test.Listpath);
         }) */

        //console.log(record.Listpath.toString().split(","))





        /* UploadService.DeleteFiles(value).
             then(response => {
                 this.setState({
                     message: "The liste was updated delete!"
                 });
 
             })
             .catch(e => {
                 //console.log(e);
             })  
         this.sayfayiyenile(); */
    }
    ListeEkle() {

        // const id = this.state.id;


        const bastar = this.state.startDate;
        const bittar = this.state.finishDate;
        const Listpath = this.state.Listpath;
        const ListID = this.state.ListID;
        const Listename = this.state.Listename;
        const AktifListe = this.state.AktifListe;

        UserService.listeekle(
            bastar,
            bittar,
            Listpath,
            ListID,
            Listename,
            AktifListe
        )
            .then(() => {

                this.setState({
                    message: "The List was Add successfully!"
                });


            })
            .catch(e => {
                ////console.log(e);
            });

        this.load()

    }
    onChange = (date) => {
        var date = new Date(date);
        var formattedDate = format(date, "yyyyMMdd");
        this.setState({ startDate: formattedDate })
        ////console.log(JSON.stringify(formattedDate));

    }
    onChangeiki = (dateiki) => {
        var date = new Date(dateiki);
        var formattedDate = format(date, "yyyyMMdd");
        this.setState({ finishDate: formattedDate })
        //console.log(JSON.stringify(formattedDate));


    }
    onListeIDChange = e => {
        ////console.log(e.target.value);
        this.setState({
            ListID: e.target.value
        });
    };
    onListeNameTitleChange = e => {
        this.setState({
            Listename: e.target.value
        });
    };
    onListeAktifChange = e => {
        this.setState({
            AktifListe: e.target.value
        });
    };
    onChangeValue = e => {

        this.setState({
            AktifListe: e.target.value
        });
    };
    handleChange = (selectedOption) => {
        this.setState({ selectedOption })


        const dropDownValueList = selectedOption.map((serverResponse => { return `${serverResponse.value}` }))
        const dropDownValueLists = selectedOption.map((serverResponse => { return `${serverResponse.label}` }))

        ////console.log(dropDownValueList.toString());
        this.setState({ Listpath: dropDownValueLists.toString() });
        ////console.log(this.state.Listpath);


    }
    openModal = () => {
        this.setState({ setIsOpen: true })
    }
    closeModal = () => {
        this.setState({ setIsOpen: false })
    }
    afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }
    rowClickedHandler = (event, data, rowIndex) => {
        ////console.log("row data", data);
        ////console.log(typeof data);

    }
    editRecord = (record, index) => {
        ////console.log("Edit record", index, record);
        ////console.log("Edit record", index, record.id);
    }
    listesil = (record, index) => {

        const id = record.id

        UserService.listesil(id).
            then(response => {
                this.setState({
                    message: "The liste was updated delete!"
                });

            })
            .catch(e => {
                ////console.log(e);
            })
        this.sayfayiyenile();
    }
    sayfayiyenile() {
        setTimeout(function () {
            window.location.reload()
        }, 1000);
    }
    AktifListeAktif = (record, index) => {
        const id = record.id//this.state.id; 
        const AktifListe = true;

        UserService.AktifListeAktif(
            id,
            AktifListe
        )
            .then(response => {
                ////console.log(response.data);
                this.setState({
                    message: "The liste was updated successfully!"
                });
                this.load()
            })
            .catch(e => {
                ////console.log(e);
            });


    }
    AktifListePasif = (record, index) => {
        const id = record.id//this.state.id; 
        const AktifListe = false;

        UserService.AktifListePasif(
            id,
            AktifListe
        )
            .then(response => {
                ////console.log(response.data);
                this.setState({
                    message: "The liste was updated successfully!"
                });
                this.load()
            })
            .catch(e => {
                ////console.log(e);
            });


    }
    listesil = (record, index) => {
        const id = record.id//this.state.id; 
        const AktifListe = true;

        UserService.listesil(
            id,
            AktifListe
        )
            .then(response => {
                ////console.log(response.data);
                this.setState({
                    message: "The liste was updated successfully!"
                });
            })
            .catch(e => {
                ////console.log(e);
            });
    }
    render() {

        const {
            currentFile,
            previewImage,
            progress,
            svalue,
            message,
            imageInfos,
            modalIsOpen
        } = this.state;



        return (
            <div>
                <div className="row">
                    <div className="col-8">
                        <label className="btn btn-default p-0">
                            <input type="file" accept="image/*" onChange={this.selectFile} />
                        </label>
                    </div>

                    <div className="col-4">
                        <button
                            className="btn btn-success btn-sm"
                            disabled={!currentFile}
                            onClick={this.upload}
                        >
                            Upload
                        </button>
                    </div>
                </div>

                {currentFile && (
                    <div className="progress my-3">
                        <div
                            className="progress-bar progress-bar-info progress-bar-striped"
                            role="progressbar"
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: progress + "%" }}
                        >
                            {progress}%
                        </div>
                    </div>
                )}

                {previewImage && (
                    <div>
                        <img className="preview" src={previewImage} alt="" />
                    </div>
                )}

                {message && (
                    <div className="alert alert-secondary mt-3" role="alert">
                        {message}
                    </div>
                )}


                <div className="card-header">liste Oluştur</div>



                <form>
                    <div>    <p />

                        <label>Başlangıç Tarihi</label>    <p />

                        <DatePicker onChange={this.onChange} />
                    </div>
                    <div>
                        <p />

                        <label>Bitiş Tarihi </label>


                        <DatePicker
                            onChange={this.onChangeiki} />
                    </div>

                    <div>
                        <label>Liste için görsel seç</label>
                        <Select
                            value={this.state.selectedOption}
                            onChange={this.handleChange}
                            options={this.state.dropDownOpt}
                            isMulti
                        />

                        {/* <SelectSearch
                           
                            options={friends}
                            renderOption={renderFriend}
                            multiple
                            search
                            filterOptions={fuzzySearch}
                            placeholder="Search friends"
                        /> */}
                    </div>

                    <div>    <p />

                        <label>Liste id Yazin </label>
                        <p />
                        <input placeholder="Liste id"
                            value={this.state.ListID}
                            onChange={this.onListeIDChange}
                        //required
                        />   <p />

                    </div>

                    <div>    <p />

                        <label>Liste Adi Yazin </label>
                        <p />
                        <input placeholder="Liste Adi"
                            value={this.state.Listename}
                            onChange={this.onListeNameTitleChange}
                        //required
                        />   <p />

                    </div>

                    <div>    <p />

                        <label>Liste Aktif ? </label>
                        <p />

                        <div onChange={this.onChangeValue}>
                            Aktif <input type="radio" value="true" />
                            <br />
                            Pasif <input type="radio" value="false" />
                        </div>


                        <p />


                    </div>

                    <div className="card-header">Liste Düzeni <p />
                        <button
                            className="btn btn-success btn-lg"
                            onClick={() => this.ListeEkle()}

                            type="submit"
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-edit"></i>
                            Liste Oluştur
                        </button>
                        <button
                            className="btn btn-danger btn-lg"
                            onClick={() => this.tumlisteyisil()}

                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-edit"></i>
                            Tüm Listeleri Sil                  </button>
                        <button
                            className="btn btn-primary btn-lg"
                            onClick={() => this.sayfayiyenile()}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-edit"></i>
                            Sayfayı yenile
                        </button>
                    </div>

                </form>



                <ReactDatatable
                    className="table table-bordered table-striped custom-class"
                    config={this.config}
                    records={this.state.listInfos}
                    columns={this.columns}
                    //  onPageChange={this.pageChange.bind(this)}
                    //  extraButtons={this.extraButtons}
                    loading={this.state.loading}
                //  onSort={this.customSort}
                //onRowClicked={this.rowClickedHandler}

                />

                <div className="card-header">Resim Önizleme </div>

                <ul className="list-group list-group-flush">


                    {imageInfos &&
                        imageInfos.map((img, index) => (

                            <li className="list-group-item" key={index}>{img.value}

                                <p>
                                    <img src={"https://mymedyal.madilink.net/files/" + img.value} height="280px" />
                                    <tr />
                                    <button
                                        className="btn btn-danger btn-sm"
                                        value={img.value}
                                        onClick={
                                            () =>
                                                this.DeleteFiles(img.value)

                                        }
                                        style={{ marginRight: '5px' }}>
                                        <i className="fa fa-edit"></i>
                                        Resmi Sil
                                    </button>
                                </p>

                            </li>
                        ))}
                </ul>
            </div>
        );
    }
}
