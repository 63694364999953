import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Progress from "./components/progress";
import { Redirect } from "react-router";
import { connect } from 'react-redux';

import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";

// import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";
 
const routes = [
     {
      path: "/home",
      component: Home
    },
    {
      path: "/login",
      component: Login
    },
   
    
    {
      path: "/progress",
      component: Progress
    },
    

];


function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={props => (
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

class App extends Component {
 /*  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }; */
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
    
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        //  showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
     
      showAdminBoard: false,
      currentUser: undefined,
      user:false
    });
  }

  render() {
    const { currentUser, showAdminBoard,user } = this.state;
   // const { match, location, history } = this.props;
    const SelectAuthenticated = () => {


      if (showAdminBoard) {
        return <Switch>
          {
            routes.map((route, i) => (

              <RouteWithSubRoutes key={i} {...route} />

            ))

          }

        </Switch>
      }


      else {
        return <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
           <Route exact path="/progress" component={Progress} />  
         </Switch>
      }



    }

    return (
      
      <div>
         <nav className="navbar navbar-expand navbar-dark bg-dark">
          <h1   className="navbar-brand">
            Madi Slayder Admin
          </h1>
          <div style={{ marginTop: 15, marginRight: 1, textAlign: "center" }}>
            {showAdminBoard && (
              <Redirect to="/progress" />
            )}
          </div>
 
          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={this.logOut}>
                  LogOut
                </a>
              </li>
            </div>

          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Login
                </Link>
              </li>

            </div>
          )}

        </nav>

        <div className="container">

          <SelectAuthenticated />

        </div>

      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  //console.log(props)
  return {
    ...state,
  }

};

export default connect(mapStateToProps)(App);
