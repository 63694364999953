import axios from 'axios';
import authHeader from './auth-header';
import http from "../http-common";

class UserService {
  getPublicContent() {
    return axios.get('https://mymedyal.madilink.net/api/test/all');
  }
  getAdminBoard() {
    return axios.get('https://mymedyal.madilink.net/api/test/admin', { headers: authHeader() });

  }




  listeekle(bastar,bittar,Listpath,ListID,Listename,AktifListe) 
  {
    return http.post("Airjin/listeekle", {bastar,bittar,Listpath,ListID,Listename,AktifListe});
  }

  hepsinisil() {
    return http.delete(`Airjin/hepsinisil`);
  }

  listesil(id) {
    return http.delete(`Airjin/listesil/${id}`);
    
  }

  listeguncelle(id, 
    bastar,
    bittar,
    Listpath,
    ListID,
    Listename,
    AktifListe ) {
    return http.put(`Airjin/listeguncelle/${id}`, 
    bastar,
    bittar,
    Listpath,
    ListID,
    Listename,
    AktifListe );
  }  

  //AktifListeAktif
  AktifListeAktif(id,AktifListe) {
    return http.put(`Airjin/AktifListeAktif/${id}`, { 
      AktifListe
    });
  }
  //AktifListePasif
  AktifListePasif(id, AktifListe) {
    return http.put(`Airjin/AktifListePasif/${id}`, { 
      AktifListe
    });
  }

}

export default new UserService();
