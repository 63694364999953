import http from "../http-common";

class UploadFilesService {
  upload(file, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);

    return http.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  getFiles() {
    return http.get("/files");
  }
  DeleteFiles(value) {
    return http.delete(`/files/${value}`, { 
    });
  }
  getList() {
    return http.get("/Airjin/tumlistegetir");
  }
 }

export default new UploadFilesService();