import { Action, combineReducers, configureStore, ThunkAction, compose } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
//import messageReducer from "../reducers/message";
//import vertifyReducer from "../reducers/vertifyReducer";
import rootSaga from './rootSaga';
import { history } from '../utils';

const rootReducer = combineReducers({
	router: connectRouter(history),
	//messageReducer,
	//vertifyReducer
});

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(sagaMiddleware, routerMiddleware(history)),
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;

